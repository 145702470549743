<template>
    <header>
        <img src="../assets/top.svg" alt="" style="width: 100%;">
        <div class="containerTip">
            <router-link to="./home" class="ins insLoge">Live Wallpaper</router-link>
            <router-link to="./home" class="ins">home</router-link>
        </div>
    </header>

    <section>
        <div class="container" style="margin-bottom: 40px;">
            <div class="row justify-content-center">
                <div class="col-lg-6">
                    <h2 class="h2ss">Terms of Use</h2>
                </div>
            </div>
            <div>
                <p class="mt-10 isContent">
                    WELCOME TO THE 【Live wallpaper-4K& HD】 (THE "SERVICE"). PLEASE READ THESE TERMS OF USE CAREFULLY BEFORE
                    USING AN APPLICATION. WE OFFER YOU ACCESS TO OUR SERVICES THROUGH OUR “MOBILE APPLICATION” (DEFINED
                    BELOW) SUBJECT TO THE FOLLOWING TERMS OF THIS AGREEMENT, WHICH MAY BE UPDATED BY US FROM TIME TO TIME
                    WITH OR WITHOUT NOTICE TO YOU. BY ACCESSING AND USING THIS, YOU ACKNOWLEDGE THAT YOU HAVE READ,
                    UNDERSTOOD, AND AGREE TO THESE TERMS AND CONDITIONS AND OUR <router-link to="./privacy-policy"
                        class="ins">PRIVACY POLICY</router-link>WHICH ARE HEREBY INCORPORATED
                    BY REFERENCE (COLLECTIVELY, THIS “AGREEMENT”). IN CASE YOU DO NOT AGREE WITH ANY OF THESE TERMS, THEN
                    PLEASE DO NOT USE THE MOBILE APPLICATION.
                </p>
                <h3 class="mt-20 mb-10 titles">
                    1. DEFINITIONS
                </h3>
                <p class="mt-10 isContent">
                    ● “Agreement” refers to this Terms and Conditions and the <router-link to="./privacy-policy"
                        class="ins">PRIVACY POLICY</router-link> and other documents provided to
                    you by the Mobile Application;</p>
                <p class="mt-10 isContent">
                    ● "User","You" and "Your" refers to the person who is accessing, taking or using any service from us.
                </p>
                <p class="mt-10 isContent">
                    ● "We", "Us", "Our" are references to 【Live wallpaper-4K& HD】.</p>
                <p class="mt-10 isContent">
                    ● "Mobile Application" shall mean and include "【Live wallpaper-4K& HD】", and any successor Mobile
                    Application or any of its affiliates;</p>
                <h3 class="mt-20 mb-10 titles">2. INTRODUCTION AND SCOPE
                </h3>
                <p class="mt-10 isContent">
                    Scope: These Terms govern your use of the Mobile Application and the Services. Except as otherwise
                    specified, these Terms do not apply to Third-Party Products or Services, which are governed by their
                    terms of service.</p>
                <p class="mt-10 isContent">Eligibility: The Services are not targeted towards, nor intended for use by,
                    anyone under the age of 13. If you are between the ages of 13 and 18, you may use the Services only
                    under the supervision of a parent or legal guardian who agrees to be bound by these Terms of Use.
                    Certain or Most of the Service of the Mobile Application is not available to any users suspended or
                    removed from the system by us for any reason.</p>
                <p class="mt-10 isContent">Electronic Communication: When you use this Mobile Application or send e-mails
                    and other electronic communications from your desktop or mobile device to us, you are communicating with
                    us electronically. By sending, you agree to receive a reply communications from us electronically in the
                    same format and you can keep copies of these communications for your records.</p>
                <h3 class="mt-20 mb-10 titles">3. PRIVACY POLICY</h3>
                <p class="mt-10 isContent">【Live wallpaper-4K& HD】 respects the privacy of its Service users. Please refer
                    to
                    our <router-link to="./privacy-policy" class="ins">PRIVACY POLICY</router-link> for information about
                    how we collect, use and disclose information about our users.
                </p>
                <h3 class="mt-20 mb-10 titles">4. CHANGES TO THIS TERMS OR SERVICE</h3>
                <p class="mt-10 isContent">We reserve the right, at our discretion, to change, modify, add to, or remove
                    portions of the Terms (collectively, “Changes”), at any time. The most current version of these Terms
                    will be posted on our Site. You may review and become familiar with any such modifications of Terms.
                    Your continued use of the Service following the posting of changes will mean that you accept and agree
                    to the Changes.
                </p>
                <h3 class="mt-20 mb-10 titles">5. GENERAL CONDITIONS
                </h3>
                <p class="mt-10 isContent">We do not guarantee the accuracy, completeness, validity, or timeliness of the
                    information listed by us.
                </p>
                <p class="mt-10 isContent">The Mobile Application is licensed to you on a limited, non-exclusive,
                    non-transferable, non-sublicensable basis, solely to be used in connection with the Service for your
                    private, personal, non-commercial use, subject to all the terms and conditions of this Agreement as they
                    apply to the Service.
                </p>
                <p class="mt-10 isContent">You further acknowledge and agree that we have no obligation whatsoever to
                    furnish any maintenance and support services concerning the App.</p>
                <p class="mt-10 isContent">You acknowledge and agree that we are not responsible for addressing any claims
                    you or any third party may have concerning the Mobile Application;
                </p>
                <p class="mt-10 isContent"><span class="isColor">【Live wallpaper】</span> reserves the right to
                    suspend/terminate usage or access to the
                    platform or system.
                </p>
                <p class="mt-10 isContent">We reserve the right, in our sole and absolute discretion, to deny your access to
                    the Mobile Application or any service, or any portion of the Mobile Application or service, without
                    notice, and to remove any content.

                </p>
                <h3 class="mt-20 mb-10 titles">6. ACCESSING OUR SERVICE
                </h3>
                <p class="mt-10 isContent">
                    Access to our Service or Mobile Application is permitted on a temporary basis, and we reserve the right
                    to amend the information or withdraw the Services we provide on our App without notice. We will not be
                    liable if for any reason our Application is unavailable at any time or for any period. You are
                    responsible for making all arrangements necessary for you to have access to our Service. You are also
                    responsible for ensuring that all persons who access our Service through your internet connection are
                    aware of these terms, and that they comply with them.</p>
                <h3 class="mt-20 mb-10 titles">7. LIMITED GUARANTEE
                </h3>
                <p class="mt-10 isContent">By this Mobile Application:</p>
                <p class="mt-10 isContent">We provide an opportunity for you to avail of the offered services from our
                    Mobile Application.
                </p>
                <p class="mt-10 isContent">We do not provide any warranty or guarantee that the service descriptions are
                    accurate, complete, reliable, current, or error-free.
                </p>
                <p class="mt-10 isContent">We may contribute updates and modifications to the Platform code. We encourage
                    you to take precautions when considering whether to use the Platform.
                </p>
                <h3 class="mt-20 mb-10 titles">8. USER RESPONSIBILITIES
                </h3>
                <p class="mt-10 isContent">You shall use the Service and Mobile Application for a lawful purpose and comply
                    with all the applicable laws while using the Mobile Application;
                </p>
                <p class="mt-10 isContent">You shall not use or access the Mobile Application for collecting any market
                    research for some competing business;
                </p>
                <p class="mt-10 isContent">You shall not misrepresent or impersonate any person or entity for any false or
                    illegal purpose;
                </p>
                <p class="mt-10 isContent">You will inform us about anything that is inappropriate or you can inform us if
                    you find something illegal;
                </p>
                <p class="mt-10 isContent">You will not interfere with or try to interrupt the proper operation of the
                    Mobile Application through the use of any virus, trojans, worms, device or other material which is
                    malicious, technologically harmful or designed to disrupt, information collection or transmission
                    mechanism, software or routine, or access or try to gain access to any data, files, connected to the
                    Mobile Application through hacking, data mining, or any other means;
                </p>
                <p class="mt-10 isContent">You must not attack our Service via a denial-of-service attack or a distributed
                    denial-of service attack. In the event of such a breach, your right to use our Service will cease
                    immediately. We will not be liable for any loss or damage caused by a distributed denial-of-service
                    attack, viruses or other technologically harmful material that may infect your computer equipment,
                    computer programs, data or other proprietary material due to your use of our Service or to your
                    downloading of any material posted on it, or on any website linked to it.
                </p>
                <p class="mt-10 isContent">You will let us know about the unsuitable content of which you become aware. If
                    you discover something that infringes any law, please let us know, and we’ll review it.
                </p>
                <p class="mt-10 isContent">You agree to comply with all applicable domestic laws, statutes, ordinances, and
                    regulations regarding your use of our Mobile Application. We reserve the right to investigate complaints
                    or reported violations of our Terms and to take any action we deem appropriate, including but not
                    limited to reporting any suspected unlawful activity to law enforcement officials, regulators, or other
                    third parties. We reserve the right, in our sole and absolute discretion, to deny you access to the
                    Mobile Application or any service, or any portion of the Mobile Application or service, without notice,
                    and to remove any content.
                </p>
                <p class="mt-10 isContent">You will not cover, obscure, block, or in any way interfere with safety features
                    (e.g., report button) on the Mobile Application;
                </p>
                <p class="mt-10 isContent">You will not use any device, scraper, or any automated thing to access the Mobile
                    Application for any means without taking permission.
                </p>
                <h3 class="mt-20 mb-10 titles">9. DISCLAIMER OF WARRANTIES; LIMITATION OF LIABILITY
                </h3>
                <p class="mt-10 isContent">The mobile application and the service are provided on an “as is” and “as
                    available” basis without any warranties of any kind, either express or implied, including that the
                    mobile application will operate error-free, implied warranties of merchantability, fitness for a
                    particular purpose or non-infringement.
                </p>
                <p class="mt-10 isContent">You acknowledge that we have no control over, and no duty to take any action
                    regarding: which users gain access to or use the Service; what effects the Content may have on you; how
                    you may interpret or use the Content; or what actions you may take as a result of having been exposed to
                    the Content. You release us from all liability for you having acquired or not acquired Content through
                    the Service. We make no representations concerning any Content contained in or accessed through the
                    Service, and we will not be responsible or liable for the accuracy, copyright compliance, legality or
                    decency of material contained in or accessed through the Service.
                </p>
                <p class="mt-10 isContent">We disclaim all licenses or warranties, including, but not limited to, licenses
                    or warranties of title, non-violation of third parties rights, and fitness for a particular purpose, and
                    any warranties arising from a matter of dealing, course of performance, or usage of trade.
                </p>
                <p class="mt-10 isContent">The mobile application may comprise technical incorrectness or typographical
                    errors or omissions. Unless required by applicable laws, we are not accountable for any such
                    typographical, technical, or pricing errors recorded on the mobile application. The mobile application
                    may contain information on certain services, not all of which are available in every location. A
                    reference to a service on the mobile applications does not suggest that such service is or will be
                    accessible in your location. We reserve the right to do changes, corrections, and/or improvements to the
                    mobile application at any time without notice.</p>
                <p class="mt-10 isContent">NO WARRANTIES
                </p>
                <p class="mt-10 isContent"><span class="isColor">【Live wallpaper】</span> specifically (but without
                    limitation) disclaims:
                </p>
                <p class="mt-10 isContent">Any implied warranties of merchantability, fitness for a particular purpose,
                    quiet enjoyment, or non-infringement; and
                </p>
                <p class="mt-10 isContent">Any warranties arising out of course-of-dealing, usage, or trade. You assume all
                    risk for any/all damages that may result from your use of or access to the services. 4K Wallpapers shall
                    not be responsible for the loss of, damage to, or unavailability of any information.
                </p>
                <p class="mt-10 isContent">NO GUARANTEE OF ACCURACY</p>
                <p class="mt-10 isContent"><span class="isColor">【Live wallpaper】</span>does not guarantee the accuracy of
                    and disclaims all liability
                    for, any errors or other inaccuracies in the information, content, recommendations, and materials made
                    available through the services.
                </p>
                <p class="mt-10 isContent">NO WARRANTIES REGARDING THIRD PARTIES
                </p>
                <p class="mt-10 isContent"><span class="isColor">【Live wallpaper】</span> makes no representations,
                    warranties, or guarantees, express or
                    implied, regarding any third-party service or information provided by a third party.
                </p>
                <p class="mt-10 isContent">TECHNICAL DISCLAIMER
                </p>
                <p class="mt-10 isContent">Every effort is made to keep the mobile application up and running smoothly.
                    However, <span class="isColor">【Live wallpaper】</span> takes no responsibility for, and will not be
                    liable for, the mobile
                    application being temporarily unavailable due to technical issues beyond our control.
                </p>
                <h3 class="mt-20 mb-10 titles">10. COPYRIGHT, TRADEMARK AND LIMITED LICENSE
                </h3>
                <p class="mt-10 isContent">The Mobile Application contains material, such as text, graphics, images,
                    designs, and other material provided by or on behalf of us (collectively referred to as the “Content”)
                    are the proprietary property of The Company or its licensors or users and are protected by copyright
                    law. Unauthorized use of the Content may infringe copyright, trademark, and other laws. You have no
                    rights in or to the Content, and you will not take the Content except as allowed under this Agreement.
                    No other use is allowed without prior written consent from us. You may not transfer, provide license or
                    sub-license, sell, or modify the Content or reproduce, display, publicly perform, make a derivative
                    version of, distribute, or otherwise use the Content in any way for any public or commercial purpose.
                    The use or posting of the Content on any other service or in a networked computer environment for any
                    purpose is expressly prohibited.
                </p>
                <p class="mt-10 isContent">You are granted a limited, non-transferable access (license) to use the
                    Application for reading of Materials; subject to these Terms. Except as expressly permitted, in these
                    Terms or otherwise in writing by us, such access does not include: (a) any resale or commercial use of
                    the Application or the Materials; (b) modifying or otherwise making any derivative uses of the App and
                    the Materials, or any portion thereof; (c) use of any data mining, robots or similar data gathering or
                    extraction methods; (d) printing, print screening or copying of any portion of the App, the Materials or
                    any information contained therein; or (e) any use of the App or the Materials other than for its
                    intended purpose. Any use of the App or the Materials other than as specifically authorized herein,
                    without our prior written permission, is strictly prohibited and will terminate the license granted
                    herein. Such unauthorized use may also violate applicable laws, including, without limitation, copyright
                    and trademark laws and applicable communications regulations and statutes. Nothing on the Mobile
                    Application should be construed as granting, by implication, estoppel, or otherwise, any license or
                    right to use the Trademarks, without our prior written permission specific for each such use. None of
                    the Content may be retransmitted without our express, written consent for every instance.
                </p>
                <p class="mt-10 isContent">Our trademarks, service marks, slogan and logos used and displayed on the Mobile
                    Application are registered and unregistered trademarks or service marks of us. Other product and service
                    names located on the Mobile Application may be trademarks or service marks owned by others (the
                    “Third-Party Trademarks,” and, collectively with us, the “Trademarks”).
                </p>
                <p class="mt-10 isContent">The look and feel of the Application, including all page headers, custom
                    graphics, button icons and scripts, is the service mark, trademark and/or trade dress of the Company and
                    is part of the Company Marks and may not be copied, imitated or used, in whole or in part, without our
                    prior written permission except as expressly permitted herein or on the Application. All other
                    trademarks, registered trademarks, product names and company names or logos mentioned in the Application
                    are the property of their respective owners and may not be copied, imitated or used, in whole or in
                    part, without the written permission of the applicable trademark holder. Reference to any products,
                    services, processes or other information, by trade name, trademark, manufacturer, supplier or otherwise
                    does not constitute or imply endorsement, sponsorship or recommendation thereof by us.
                </p>
                <h3 class="mt-20 mb-10 titles">11. EXCLUSION OF LIABILITY
                </h3>
                <p class="mt-10 isContent">We accept no responsibility for delays/errors due to circumstances outside of our
                    ruling (Force Majeure). These circumstances can be, for example, labor conflict, fire etc.
                </p>
                <p class="mt-10 isContent">You understand and agree that we (a) do not guarantee the accuracy, completeness,
                    validity, or timeliness of information listed by us or any third parties; and (b) shall not be
                    responsible for any materials posted by us or any third party. You shall use your judgment, caution, and
                    common sense in evaluating any prospective methods or offers and any information provided by us or any
                    third party.
                </p>
                <h3 class="mt-20 mb-10 titles">12. GEOGRAPHIC RESTRICTION
                </h3>
                <p class="mt-10 isContent">We reserve the right, but not the obligation, to limit the usage or supply of any
                    service to any person, geographic region, or jurisdiction. We may use this right as per necessity. We
                    reserve the right to suspend any Service at any time. Any offer to provide any Service made on this
                    Mobile Application is invalid where banned.
                </p>
                <h3 class="mt-20 mb-10 titles">13. INDEMNIFICATION
                </h3>
                <p class="mt-10 isContent">You agree to defend, release, indemnify, and hold us and assigns harmless from
                    and against any claims, losses, liabilities, expenses, damages, costs (including attorneys’ fees and
                    court costs), actions, or demands of any kind, including, without limitation, reasonable legal and
                    accounting fees, arising or resulting from your use of the Service, your breach of this Agreement or
                    your misuse of the Content or the Mobile Application, and any of your acts or omissions that implicate
                    publicity rights, defamation or invasion of privacy. We reserve the right, at its own expense, to assume
                    exclusive defense and control of any matter otherwise subject to indemnification by you and, in such
                    case, you agree to cooperate with us in the defense of such matter.
                </p>
                <h3 class="mt-20 mb-10 titles">14. NO RESPONSIBILITY
                </h3>
                <h4 class="mt-20 mb-10 titles">We are not responsible to you for:</h4>
                <p class="mt-10 isContent">any losses you suffer because you cannot use our Mobile Application at any time;
                    or
                    any errors in or omissions from our Mobile Application; or
                    any unauthorized access or loss of personal information that is beyond our control.
                </p>
                <h3 class="mt-20 mb-10 titles">15. SPAM POLICY
                </h3>
                <p class="mt-10 isContent">You are strictly prohibited from using the Mobile Application or any of our
                    Services for illegal spam activities, including gathering personal information from others.
                </p>
                <h3 class="mt-20 mb-10 titles">16. THIRD PARTY SERVICES AND CONTENT
                </h3>
                <p class="mt-10 isContent">The Service may provide third party content on the Application and may provide
                    links to web pages(“External Sites”) and content that are not owned or controlled by us, including but
                    not limited to Company Platform and any third party advertisements or promotions (collectively the
                    “Third Party Content”) as a service to those interested in this information. We do not control, endorse
                    or adopt any Third Party Content and makes no representation or warranties of any kind regarding the
                    Third Party Content, including without limitation regarding its accuracy or completeness. You
                    acknowledge and agree that we are not responsible or liable in any manner for any Third Party Content
                    and undertakes no responsibility to update or review any Third Party Content. Users use such Third Party
                    Content contained therein at their own risk. You should take safety measures when you are downloading
                    files from all these websites to safeguards your computer or device from viruses and other critical
                    programs. If you agree to access linked External Sites, you do so at your own risk.
                </p>
                <h3 class="mt-20 mb-10 titles">17. ERRORS, INACCURACIES, AND OMISSIONS
                </h3>
                <p class="mt-10 isContent">Every effort has been taken to ensure that the information offered on this Mobile
                    Application is accurate and error-free. We apologize for any errors or omissions that may have occurred.
                    We cannot give you any warranty that usage of the Mobile Application will be error-free or fit for
                    purpose, timely, that defects will be amended, or that the Mobile Application or the server that makes
                    it available are free of viruses or bugs or signifies the full functionality, accuracy, reliability of
                    the Mobile Application and we do not make any warranty whatsoever, whether express or implied, relating
                    to fitness for purpose, or accuracy.
                </p>
                <h3 class="mt-20 mb-10 titles">18. MISCELLANEOUS
                </h3>
                <h4 class="mt-20 mb-10 titles">FEEDBACK</h4>
                <p class="mt-10 isContent">You acknowledge and agree that any materials, including but not limited to
                    questions, comments, feedback, suggestions, ideas, plans, notes, drawings, original or creative
                    materials or other information, regarding the <span class="isColor">【Live wallpaper】</span> or the
                    Service (collectively, “Feedback”)
                    that are provided by you, whether by email, are non-confidential and will become our sole property. We
                    will own exclusive rights, including all intellectual property rights, and will be entitled to the
                    unrestricted use and dissemination of such Feedback for any purpose, commercial or otherwise, without
                    acknowledgment or compensation to you.
                </p>
                <h4 class="mt-20 mb-10 titles">SEVERABILITY</h4>
                <p class="mt-10 isContent">If any provision of these Terms is found to be unenforceable or invalid, that
                    provision will be limited or eliminated to the minimum extent necessary so that the Terms will otherwise
                    remain in full force and effect and enforceable.
                </p>
                <h4 class="mt-20 mb-10 titles">TERMINATION</h4>
                <h4 class="mt-20 mb-10 titles">TERMS</h4>
                <p class="mt-10 isContent">The Services will be provided to you can be canceled or terminated by us. We may
                    terminate these Services at any time, with or without cause, without providing any notice. We will have
                    no liability to you or any third party because of such termination. Termination of these Terms will
                    terminate all of your Services.
                </p>
                <h4 class="mt-20 mb-10 titles">EFFECT OF TERMINATION</h4>
                <p class="mt-10 isContent">Upon termination of these Terms for any reason, or cancellation or expiration of
                    your Services: (a) We will cease providing the Services; (b) You will not be entitled to any refunds or
                    usage fees, or any other fees or otherwise;
                </p>
                <h4 class="mt-20 mb-10 titles">GOVERNING LAW</h4>
                <p class="mt-10 isContent">The terms herein will be governed by and construed under the law of India and the
                    State of Maharashtra without giving effect to any principles of conflicts of law. The Courts of State of
                    Maharashtra shall have exclusive jurisdiction over any dispute arising from the use of the Mobile
                    Application.
                </p>
                <h4 class="mt-20 mb-10 titles">FORCE MAJEURE</h4>
                <p class="mt-10 isContent">We will have no liability to you, your users, or any third party for any failure
                    us to perform its obligations under these Terms if such non-performance arises as a result of the
                    occurrence of an event beyond the reasonable control of us, including, without limitation, an act of war
                    or terrorism, natural disaster, failure of electricity supply, riot, or other force majeure event.
                </p>
                <h4 class="mt-20 mb-10 titles">CONTACT INFORMATION</h4>
                <p class="mt-10 isContent">If you have any questions about these Terms, please contact us with <span
                        class="isLink">floralong20231020@gmail.com</span>
                </p>
            </div>
        </div>
    </section>
</template>
  
<script>
export default {
    // 组件逻辑
}
</script>

<style scoped>
header {
    position: relative;

    .containerTip {
        position: absolute;
        width: 100%;
        top: 50%;
        display: flex;
        justify-content: space-between;
        padding: 0 7%;

        .ins {
            color: #FFF;
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: PingFang SC;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            text-decoration: auto;
        }

        .insLoge {
            font-size: 48px;
        }
    }
}

section {
    .isColor {
        color: #000;
        font-weight: 600;
    }

    .isLink {
        color: #2a73f7;
    }

    .col-lg-6 {
        margin: 60px 0 80px 0;

        .h2ss {
            font-size: 64px;
            color: #333;
            font-weight: 500;
            text-align: center;
        }
    }

    .mb-10 {
        margin-bottom: 10px;
    }

    .mt-20 {
        margin-top: 20px;
    }

    p {
        text-indent: 25px;
    }

    .titles {
        color: #333;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: PingFang SC;
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
    }

    .isContent {
        color: #404040;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: PingFang SC;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
    }
}
</style>