<template>
    <header>
        <img src="../assets/top.svg" alt="" style="width: 100%;">
        <div class="containerTip">
            <router-link to="./home" class="ins insLoge">Live Wallpaper</router-link>
            <router-link to="./home" class="ins">home</router-link>
        </div>
    </header>

    <section>
        <div class="container" style="margin-bottom: 40px;">
            <div class="row justify-content-center">
                <div class="col-lg-6">
                    <h2 class="h2ss">PRIVACY POLICY</h2>
                </div>
            </div>
            <div>
                <h3 class="mt-20 mb-10 titles">👋 Welcome to Live Wallpaper 3D & HD!
                </h3>
                <p class="mt-10 isContent">
                    This Privacy Policy (“Privacy Policy”), is an integral part of our End User License Agreement (“EULA”),
                    and governs the processing and transfer of personal data collected or processed by Flora2023. (“Studio”,
                    “we”, “us” or “our”) when you accessing or using our mobile application or our services, all as detailed
                    in the applicable EULA (respectively the “App” and “Service”).
                </p>
                <h3 class="mt-20 mb-10 titles">👉 1. PRIVACY NOTICE & POLICY AMENDMENTS
                </h3>
                <p class="mt-10 isContent">
                    We reserve the right to amend this Policy from time to time, at our sole discretion. The most recent
                    version of the Policy will always be posted on the website. The updated date of the Policy will be
                    reflected in the “Last Modified” heading. We will provide notice to you if these changes are material,
                    and, where required by applicable law, we will obtain your consent. Any amendments to the Policy will
                    become effective within 30-days upon the display of the modified Policy. We recommend you review this
                    Policy periodically to ensure that you understand our most updated privacy practices.

                </p>
                <h3 class="mt-20 mb-10 titles">👉2. What information does the Application obtain and how is it used?
                </h3>
                <p class="mt-10 isContent">
                    User Provided Information The Application obtains the information you provide when you download,
                    register and use the Application.</p>
                <p class="mt-10 isContent">(a) We may also collect from you, directly or indirectly, during your access or
                    interaction with the
                    website or Services, individually identifiable information, namely information that identifies an
                    individual or may, with reasonable effort, be used to identify an individual (“Personal Data”).</p>
                <p class="mt-10 isContent">(b)Transaction-related information, such as when you respond to any offers, or
                    download or use applications from us;</p>
                <p class="mt-10 isContent">(c) the information you provide us when you contact us for help;</p>
                <p class="mt-10 isContent">(d) the information you enter into our system when using the Application, such as
                    likes, downloads and
                    artists you follow;</p>
                <p class="mt-10 isContent">We may use the information you provided us to contact you from time to time to
                    provide you with
                    important information, required notices, and marketing promotions.</p>
                <p class="mt-10 isContent">Automatically Collected Information In addition, the Application may collect the
                    following information
                    automatically: the type of mobile device you use, your mobile devices unique device ID, your IP address,
                    your advertising ID, your mobile operating system, the type of mobile Internet browsers you use, the
                    name of your mobile operator or ISP, language, time zone, and information about the way you use the
                    Application.</p>
                <p class="mt-10 isContent">We use this information to better personalize the content (including ads) or
                    features you see.</p>
                <p class="mt-10 isContent">Access to third parties services account This type of services allows this
                    Application to access Data
                    from your account on a third-party service and perform actions with it. These services are not activated
                    automatically, but require explicit authorization by the User.</p>
                <h3 class="mt-20 mb-10 titles">👉Access to the Facebook account
                </h3>
                <p class="mt-10 isContent">
                    This service allows this Application to connect with the User’s account on the Facebook social network,
                    provided by Facebook, Inc.</p>
                <p class="mt-10 isContent">Permissions asked: Email and Public Profile</p>
                <p class="mt-10 isContent">Place of processing: US

                </p>
                <h3 class="mt-20 mb-10 titles">👉Access to the Google+ account
                </h3>
                <p class="mt-10 isContent">This service allows this Application to connect with the User’s account on the
                    Google+ social network,
                    provided by Google.</p>
                <p class="mt-10 isContent">Permissions asked: Email and Profile ID</p>
                <p class="mt-10 isContent">Place of processing: US</p>
                <p class="mt-10 isContent">Users are responsible for any Personal Data of third parties obtained, published
                    or shared through this
                    Application and confirm that they have the third party’s consent to provide the Data to the Owner.</p>
                <h3 class="mt-20 mb-10 titles">👉3. Does the Application collect precise real-time location
                </h3>
                <h3 class="mt-20 mb-10 titles">information of the device?
                </h3>
                <p class="mt-10 isContent">
                    When you visit the mobile application, we may use GPS technology (or other similar technology) to
                    determine your current location in order to determine the city you are located within and display a
                    location map with relevant advertisements. If you do not want us to use your location for the purposes
                    set forth above, you should turn off the location services for the mobile application located in your
                    account settings or in your mobile phone settings and/or within the mobile application. The Use of the
                    Collected Data We use information about you for the following purposes in accordance with the legal
                    bases for each type of personal data as described below:</p>
                <p class="mt-10 isContent">(a) to provide, personalize and improve our Products and services you request and
                    send you related
                    information, as requested by you / as, agreed with you;</p>
                <p class="mt-10 isContent">(b) to provide and maintain the App experience;</p>
                <p class="mt-10 isContent">(c) to send you technical notices, updates, security alerts, and support and
                    administrative messages;
                    Our processing for the above purposes is necessary for the performance of a contract to which you are
                    party in order to support the operation of the App, facilitate the delivery of requested products and
                    services and enable maintenance and update of the App, see Article 6(1)(b) of the GDPR.</p>
                <p class="mt-10 isContent">(d) to provide you with news and information about the Application;</p>
                <p class="mt-10 isContent">(e) to monitor and analyze usage of the Application; Our processing for the above
                    purposes is justified
                    by our legitimate interests in providing advertisements and content of interest to you and improving our
                    services in accordance with Article 6(1)(f) of the GDPR (also known as “the balancing-of-interest
                    rule”).</p>
                <p class="mt-10 isContent">(f) to provide users that have given us consent to share their advertising IDs
                    with our ad network
                    partners for the purpose of serving them with personalized advertisement in the Application. Our
                    processing for the above purpose is justified by our legitimate interests in providing advertisements
                    and content of interest to you in accordance with Article 6(1)(f) of the GDPR. The disclosure of your
                    advertising ID to third parties for the purposes of behavioral advertising is based on your consent in
                    accordance with Article 6(1)(a) of the GDPR (see more below under Sharing of Information). Storage of
                    information We will retain User Provided data for as long as you use the Application and for a
                    reasonable time thereafter. We will retain Automatically Collected information for up to 24 months and
                    thereafter may store it in aggregate. If you’d like us to delete User Provided Data that you have
                    provided via the Application, please contact us with <span
                        class="isLink">floralong20231020@gmail.com</span> and we will respond in a
                    reasonable time. Please note that some or all of the User Provided Data may be required in order for the
                    Application to function properly.

                </p>

                <h3 class="mt-20 mb-10 titles">👉4. General Data Protection Regulation (GDPR)

                </h3>

                <p class="mt-10 isContent">
                    Flora2023 studio is committed to user privacy globally, and personal information under users' control and minimal collection of data are our existing practices. According to the requirements of the General Data Protection Regulation (GDPR) of the European Union (EU), we collect and process the data in a specific manner of users in EU outlined in this Privacy Policy on one of the following bases,If you are based in the EEA or the United Kingdom, in certain circumstances you have rights under data protection laws in relation to your personal information that we hold about you—specifically:
                </p>
                
                <p class="mt-10 isContent">
                    Request access to your personal information. You may have the right to request access to any personal information we hold about you as well as related information, including the purposes for processing the personal information, the recipients or categories of recipients with whom the personal information has been shared, where possible, the period for which the personal information will be stored, the source of the personal information, and the existence of any automated decision making.</p>    
                <p class="mt-10 isContent">
                    Request correction of your personal information. You may have the right to obtain without undue delay the rectification of any inaccurate personal information we hold about you.
                </p>    
                <p class="mt-10 isContent">
                    Request erasure of your personal information. You may have the right to request that personal information held about you be deleted.</p>    
                <p class="mt-10 isContent">
                    Request restriction of processing your personal information. You may have the right to prevent or restrict processing of your personal information.</p>    
                <p class="mt-10 isContent">
                    Request transfer of your personal information. You may have the right to request transfer of your personal information directly to a third party where this is technically feasible.
                </p>  
                <p class="mt-10 isContent">
                        Where you believe that we have not complied with our obligations under this Privacy Policy or applicable data protection law, we ask that you contact us first to see if we can resolve the issue. However, you have the right to make a complaint to a supervisory authority, such as the UK Information Commissioner’s Office.</p> 
                <h3 class="mt-20 mb-10 titles">👉5. CALIFORNIA RESIDENTS
                </h3>
                <h3 class="mt-20 mb-10 titles">Your California Privacy Rights
                </h3>         
                <p class="mt-10 isContent">
                    California residents with an established business relationship with us are permitted by California law once a year to request information about the way we shared certain categories of information with others for their marketing purposes during the prior calendar year.
                </p> 
                <p class="mt-10 isContent">
                    We may share your personal information with others for their marketing use unless you tell us not to. To opt-out, please update your ad and content preferences within the settings section of our Mobile Apps, email us at privacy@zedge.net, or call us on 1 844 219 5326. We will not share your information after we have received and processed your opt-out request.
                </p> 
                <p class="mt-10 isContent">
                    The California Consumer Privacy Act of 2018 (“CCPA”) provides California residents with specific rights regarding their personal information. This section describes your CCPA rights and explains how to exercise those rights.
                </p> 
                <p class="mt-10 isContent">
                    Verified California residents have the right to:
                </p>
                <p class="mt-10 isContent">
                    (a) request and receive disclosure of our information collection practices during the prior 12 months, including the categories of personal information we collect, the categories of sources of such information, our business purpose for collecting or sharing such information, and the categories of third parties with whom we share such information;
                  </p>
                <p class="mt-10 isContent">
                    (b) request and receive disclosure of our information sharing practices during the prior 12 months, including a list of the categories of personal information sold with the category of third-party recipients and a list of the categories of personal information that we disclosed for a business purpose;
                </p>
                <p class="mt-10 isContent">
                    (c) request and receive a copy of the specific personal information we have collected about them during the prior 12 months;
                </p>
                <p class="mt-10 isContent">
                    (d) request that we not sell personal information about them; 
                </p>
                <p class="mt-10 isContent">
                    (e) request that we delete (and direct our service providers to delete) their personal information subject to certain exceptions.
                </p>
                <p class="mt-10 isContent">
                    For purposes of the CCPA personal information means information that identifies, relates to, describes, is capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular California resident or household.
                </p>
                <p class="mt-10 isContent">
                    The CCPA rights described above do not apply to information collected in the employment context about our current, former or prospective employees or contractors (who receive separate disclosures under the CCPA) or to information collected about California business contacts (employees, owners, directors, officers, or contractors of companies, sole proprietorships, and other entities collected in the context of conducting due diligence regarding, or providing or receiving a product or service to or from, such companies, sole proprietorships, or entities). California business contacts have the right to tell us not to sell their information; please see below for how to exercise this right.
                </p>
                <h3 class="mt-20 mb-10 titles">Information collected, sources, and business purpose for collecting information.
                </h3>  
                <p class="mt-10 isContent">
                    During the past 12 months we may have collected the following categories of personal information. This includes information that individuals provide to us directly, information we collect automatically through the website, information that we collect when individuals interact with us such as through online postings, and information that we may collect from third parties such as service providers, affiliated companies, marketing, staffing, or data partners, or other third parties. It also includes information that we collect about employees and business partners and vendors from those individuals directly or from references, referrals, or consumer reporting agencies. Not all information is collected from everyone who interacts with us. Information we collect is used for the business purposes described above in section 2 “What information does the Application obtain and how is it used?”.
                </p>
                <p class="mt-10 isContent">
                    (a) Identifiers such as contact information (name, address, phone number, email or postal address), unique personal identifiers (that may include but are not limited to a legal name or preferred alias and online identifiers like user account names), and an encrypted version of your password. We may collect additional information from suppliers, vendors, or employees including business contact information, phone number, email and postal addresses and titles.
                </p>
                <p class="mt-10 isContent">
                    (b) Sensitive information such as financial and payment information like credit or debit card information, or payment information received from a third party payment provider such as Google Play or App Store (Apple).
                </p>
                <p class="mt-10 isContent">
                    (c) Commercial information such as transaction histories, billing and shipping information, and product preferences.
                </p>
                <p class="mt-10 isContent">
                    (d) Electronic network activity information such as the internet protocol (IP) address collected from users’ computers and mobile devices, and information about online activity, including your interactions with our website, through the use of cookies and similar technologies, system logs, crash logs, and analytics monitoring.
                </p>
                <p class="mt-10 isContent">
                    (e) Geolocation information.
                </p>
                <p class="mt-10 isContent">
                    (f) Audio or video information such as call center recordings or monitoring records from our customer support centers and security video recordings at our facilities.
                </p>
                <p class="mt-10 isContent">
                    (g) Employment, education and professional related information, protected classification information, biometrics (collected from current and prospective employees, contractors, service providers, vendors and suppliers).
                </p>
                <p class="mt-10 isContent">
                    (h) Other types of personal information that we may disclose to you prior to the point of first collection.
                </p>
                <p class="mt-10 isContent">
                    We will ask you for information that allows us to reasonably verify your identity (that you are the person about whom we collected personal information) and will use that information only for that purpose. We may request that you submit a signed statement under penalty of perjury that you are the individual you claim to be. We will acknowledge receipt of your request within 10 days and will endeavor to respond within forty-five days of receipt of your request, but if we require more time (up to an additional forty-five days) we will notify you of our need for additional time. For requests that we not sell your information we will comply with your request within 15 business days. We cannot respond to your request or provide you with personal information if we cannot verify your identity and confirm that the personal information relates to you.

                </p>
                <p class="mt-10 isContent">
                    You may make a request for disclosure of our information collection practices, the specific information we collected about you, or our information sharing practices up to twice within a 12-month period. You may make a request that we not sell information or for deletion of your information at any time.

                </p>
                <p class="mt-10 isContent">
                    For requests for a copy of the personal information we have collected during the 12 months prior to your request we will endeavor to provide the information in a format that is readily useable, including by mailing you a paper copy or providing an electronic copy to your registered account, if you have registered an account with us.

                </p>
                <p class="mt-10 isContent">
                    For requests for deletion of your information please understand that California law permits us to retain certain information and not to delete it under certain circumstances. By way of example, we are not required to comply with a request to delete information if the information is necessary for us to complete a transaction for you or otherwise perform a contract; to detect, protect against, or prosecute security incidents, fraud or illegal activity; to use the information only internally in ways reasonably aligned with your expectations as our customer (such as maintaining sales records), and to comply with legal obligations. If we receive such a request from you, we will notify any service providers we have engaged to delete your information as well.

                </p>
                <p class="mt-10 isContent">
                    We will not discriminate against you as a result of your exercise of any of these rights.
                </p>
                <p class="mt-10 isContent">
                    Using an Authorized Agent. You may submit a request through someone holding a formal Power of Attorney. Otherwise, you may submit a request using an authorized agent only if (i) the person is registered with the Secretary of State to do business in California; (ii) you provide the authorized agent with signed written permission to make a request; (iii) you verify directly with us that you have authorize the person to make the request on your behalf; (iv) you verify your own identity directly with us; and (v) your agent provides us with proof that they are so authorized. We will require the agent to submit proof to us that they have been authorized to make requests on your behalf.
                </p>
                <p class="mt-10 isContent">
                    Personal information sales opt-out and opt-in rights. We do not sell your information for monetary consideration, but we may transfer your information to a third party that provides us with services such as helping us with advertising, data analysis and security, which may fall under the definition of for “other valuable consideration” which may be considered a ‘sale’ under the CCPA. During the past 12 months we disclosed identifiers, electronic network activity, geolocation, and inferences from such collected information with third parties such as advertising and analytics providers and those third parties listed in section 9 (TRANSFERS OF PERSONAL INFORMATION) above, for a business purpose which falls within the definition of a ‘sale’. If you are 16 years of age or older, you have the right to direct us to not sell your personal information at any time (the “right to opt-out”). We do not sell the personal information of individuals we know are less than 16 years of age. To exercise the right to opt-out, you (or your authorized representative) may update your ad and content preferences within the settings section of our Mobile Apps, email us at privacy floralong20231020@gmail.com. Once you make an opt-out request, we will wait at least 12 months before asking you to reauthorize personal information sales.
                </p>
                <h3 class="mt-20 mb-10 titles">👉6. How is this information shared?
                </h3>
                <h4 class="mt-20 mb-10 titles">We disclose information about you to the following categories of third
                    parties:
                </h4>
                <p class="mt-10 isContent">
                    (a) as required by law, such as to comply with a subpoena or similar legal process;</p>
                <p class="mt-10 isContent">(b) when we believe in good faith that disclosure is necessary to protect our
                    rights, protect your
                    safety or the safety of others, investigate fraud, or respond to a government request;</p>
                <p class="mt-10 isContent">(c) with our trusted services providers who work on our behalf, do not have an
                    independent use of the
                    information we disclose to them and have agreed to adhere to the rules set forth in this privacy
                    statement.</p>
                <p class="mt-10 isContent">(d) other users in order to provide certain features built into the Application
                </p>
                <p class="mt-10 isContent">(e) Ad networks in order to serve advertisements such as:</p>
                <p class="mt-10 isContent">Facebook ads: <a class="isLink"
                        href="https://www.facebook.com/about/privacy/update">https://www.facebook.com/about/privacy/update
                    </a></p>
                <p class="mt-10 isContent">Google:<a class="isLink"
                        href="https://policies.google.com/privacy/partners">https://policies.google.com/privacy/partners</a>
                </p>
                <p class="mt-10 isContent">MoPub: <a class="isLink"
                        href="https://www.mopub.com/legal/privacy">https://www.mopub.com/legal/privacy</a></p>
                <p class="mt-10 isContent">PubNative:Privacy Policy | PubNative - Advanced Mobile Monetization </p>
                <p class="mt-10 isContent"> <a class="isLink"
                        href="http://pubnative.net/privacy-policy/">http://pubnative.net/privacy-policy/</a>
                </p>
                <p class="mt-10 isContent">AppLovin:<a class="isLink"
                        href="https://www.applovin.com/privacy/">https://www.applovin.com/privacy/</a></p>
                <p class="mt-10 isContent">MobVista:Privacy Policy - Mobvista<a class="isLink"
                        href="https://www.mobvista.com/en/privacy/**"> https://www.mobvista.com/en/privacy/**</a></p>
                <p class="mt-10 isContent">(f) Analytics companies to help us understand the usage of our Application: </p>
                <p class="mt-10 isContent">Google Firebase: <a class="isLink"
                        href="https://policies.google.com/privacy/partners">https://policies.google.com/privacy/partners</a>
                </p>
                <p class="mt-10 isContent">Facebook Analytics:<a class="isLink"
                        href="https://www.facebook.com/about/privacy/update">https://www.facebook.com/about/privacy/update</a>
                </p>
                <p class="mt-10 isContent">Flurry:<a class="isLink"
                        href="https://policies.oath.com/us/en/oath/privacy/products/developer/index.html">https://policies.oath.com/us/en/oath/privacy/products/developer/index.html</a>
                </p>
                <p class="mt-10 isContent">Fabric:<a class="isLink"
                        href="https://policies.google.com/privacy">https://policies.google.com/privacy</a></p>
                <p class="mt-10 isContent">(g) Marketing companies that provide us services that allow sending push
                    notifications, transactional
                    and marketing emails and in-app message: </p>
                <p class="mt-10 isContent">In addition, we use server hosting providers, CDN solution
                    providers, database
                    solutions and other
                    solutions that allow us to process all information for the normal operations of the
                    Application.</p>
                <p class="mt-10 isContent">If Stanga or Shine Media is involved in a merger,
                    acquisition, or sale of all or a
                    portion of its
                    assets, you will be notified via email and/or a prominent notice on our Website of any change in
                    ownership or uses of this information, as well as any choices you may have regarding this
                    information.</p>
                <p class="mt-10 isContent">If you’d like to opt-out from third party use of this type
                    of information to help
                    serve targeted
                    advertising, please visit the section entitled “Opt-out”</p>
                <h4 class="mt-20 mb-10 titles">Transfer of information to third countries</h4>
                <p class="mt-10 isContent">As explained in section 4 we share certain information with third parties which
                    might store your information in countries that are outside your country and outside the European
                    Union. We choose our third parties carefully and only work with those that are fully compliant with
                    our privacy policy and all laws and regulation in place concerning the processing of personal
                    information.
                </p>
                <h4 class="mt-20 mb-10 titles">How can you exercise your rights provided under the GDPR?</h4>
                <p class="mt-10 isContent">Under the General Data Protection Regulation, you have the right to access,
                    rectify, port and erase your data. To exercise these rights please contact with
                    <span class="isLink">floralong20231020@gmail.com</span> We will answer your requests immediately in
                    any event within one month
                    of our receipt of the request.
                </p>
                <h4 class="mt-20 mb-10 titles">What are my opt-out rights?</h4>
                <p class="mt-10 isContent">Under the General Data Protection Regulation, you have the right to access,
                    There are multiple opt-out options for users of this Application:
                </p>
                <p class="mt-10 isContent">Opt-out of all information collection by uninstalling the Application: You can
                    stop
                    all collection of information by the Application easily by uninstalling the Application. You may use
                    the standard uninstall processes as may be available as part of your mobile device or via the mobile
                    application marketplace or network.
                </p>
                <p class="mt-10 isContent">Opt-out from the use of information to serve targeted advertising by advertisers
                    and/or third party network advertisers: you may at any time opt-out from further allowing us to have
                    access to your location data by following these steps: in your device, go to Settings => Google =>
                    Ads, and check “opt-out of interested-based ads” .
                </p>
                <h4 class="mt-20 mb-10 titles">The right to withdraw consent</h4>
                <p class="mt-10 isContent">If we asked for your consent to process specific personal information as
                    described
                    in the sections above you have the right to withdraw your consent at any time by contacting with
                    <span class="isLink">floralong20231020@gmail.com</span>.
                </p>
                <h3 class="mt-20 mb-10 titles">Children</h3>
                <p class="mt-10 isContent">We do not use the Application to knowingly solicit data from or market to
                    children
                    under the age of 13. If a parent or guardian becomes aware that his or</p>
                <p class="mt-10 isContent">her child has provided us with information without their consent, he or she
                    should
                    contact us with <span class="isLink">floralong20231020@gmail.com</span>. We will delete such
                    information from
                </p>
                our files within a reasonable time.
                <h3 class="mt-20 mb-10 titles">Security</h3>
                <p class="mt-10 isContent">We are concerned about safeguarding the confidentiality of your information. We
                    provide physical, electronic, and procedural safeguards to protect</p>
                <p class="mt-10 isContent">the information we process and maintain. For example, we limit access to this
                    information to authorized employees and contractors who need to know that information in order to</p>
                <p class="mt-10 isContent">operate, developer improves our Application. Please be aware that, although we
                    endeavor to provide reasonable security for information we process and maintain, no security system
                    can prevent all potential security breaches.</p>
                <h3 class="mt-20 mb-10 titles">Contact us</h3>
                <span class="mt-10 isContent">If you want to contact us, with
                    <span class="isLink">floralong20231020@gmail.com</span>.
                </span>
            </div>
        </div>
    </section>
</template>
  
<script>
export default {
    // 组件逻辑
}
</script>

<style scoped>
header {
    position: relative;

    .containerTip {
        position: absolute;
        width: 100%;
        top: 50%;
        display: flex;
        justify-content: space-between;
        padding: 0 7%;

        .ins {
            color: #FFF;
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: PingFang SC;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            text-decoration: auto;
        }

        .insLoge {
            font-size: 48px;
        }
    }
}

section {
    .isLink {
        color: #2a73f7;
    }

    .col-lg-6 {
        margin: 60px 0 80px 0;

        .h2ss {
            font-size: 64px;
            color: #333;
            font-weight: 500;
            text-align: center;
        }
    }

    .mb-10 {
        margin-bottom: 10px;
    }

    .mt-20 {
        margin-top: 20px;
    }

    p {
        text-indent: 25px;
    }

    .titles {
        color: #333;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: PingFang SC;
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
    }

    .isContent {
        color: #404040;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: PingFang SC;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
    }
}
</style>